import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './Layouts/home-layout/home-layout.component';
import { DashboardLayoutComponent } from './Layouts/dashboard-layout/dashboard-layout.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AuthGuardGuard , checkAdminRoleGuard} from './_guards/auth-guard.guard';

const routes: Routes = [
  {
  path: '',
  component: HomeLayoutComponent,
  children:[
    {
    path: '',
    redirectTo:'/',
    pathMatch:'full'
    },
    {
      path: '',
      loadChildren: () => import('./Home/auth/auth.module').then(m => m.AuthModule)
    }

  ]
  },
  {
  path: '',
  component: DashboardLayoutComponent,
  children:[
    {
    path: '',
    redirectTo:'dashboard',
    pathMatch:'full'
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./Dashboard/dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
          roleId: 0
      }
    },
    {
      path: 'appointment',
      loadChildren: () => import('./Dashboard/appointment/appointment.module').then(m => m.AppointmentModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:8
      }
    },
    {
      path: 'expiring-contracts',
      loadChildren: () => import('./Dashboard/expiring-contacts/expiring-contacts.module').then(m => m.ExpiringContactsModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:1
      }
    },
    {
      path: 'customers',
      loadChildren: () => import('./Dashboard/customer/customer.module').then(m => m.CustomerModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
          roleId:1
      }
    }
    ,
    {
      path: 'partners',
      loadChildren: () => import('./Dashboard/partner/partner.module').then(m => m.PartnerModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:12
      }
    },
    {
      path: 'agents',
      loadChildren: () => import('./Dashboard/agent/agent.module').then(m => m.AgentModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:9
      }
    },
    {
      path: 'agent/commision',
      loadChildren: () => import('./Dashboard/commission/commission.module').then(m => m.CommissionModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:9
      }
    },
    {
      path: 'category',
      loadChildren: () => import('./Dashboard/category/category.module').then(m => m.CategoryModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:10
      }
    }
    ,
    {
      path: 'subcategory',
      loadChildren: () => import('./Dashboard/subcategory/subcategory.module').then(m => m.SubcategoryModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:10
      }
    }
    ,
    {
      path: 'product',
      loadChildren: () => import('./Dashboard/product/product.module').then(m => m.ProductModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:10
      }
    }
    ,
    {
      path: 'task',
      loadChildren: () => import('./Dashboard/task/task.module').then(m => m.TaskModule),
      canActivate: [AuthGuardGuard],
      data:{
          role: ''
      }
    }
    ,
    {
      path: 'financial_statement',
      loadChildren: () => import('./Dashboard/financial-statement/financial-statement.module').then(m => m.FinancialStatementModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:3
      }
    }
    ,
    {
      path: 'role-access',
      loadChildren: () => import('./Dashboard/role-access/role-access.module').then(m => m.RoleAccessModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:11
      }
    }
    ,
    {
      path: 'data-analysis',
      loadChildren: () => import('./Dashboard/data-analysis/data-analysis.module').then(m => m.DataAnalysisModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
          roleId:4 
      }
    },
    {
      path: 'proposal',
      loadChildren: () => import('./Dashboard/proposals/proposals.module').then(m => m.ProposalsModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId: 2
      }
    },
    {
      path: 'newsletter',
      loadChildren: () => import('./Dashboard/newsletter/newsletter.module').then(m => m.NewsletterModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:5
      }
    },
    {
      path: 'appointment',
      loadChildren: () => import('./Dashboard/appointment/appointment.module').then(m => m.AppointmentModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:8
      }
    },
    {
      path: 'customer-executive',
      loadChildren: () => import('./Dashboard/subadmin/subadmin.module').then(m => m.SubadminModule),
      canActivate: [AuthGuardGuard,checkAdminRoleGuard],
      data:{
        roleId:13
      }
    }
    
  ]
  },
  { 
    path: '**', 
    pathMatch: 'full', 
    component: PagenotfoundComponent 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

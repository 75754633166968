    <div class="dropdown lang-dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        <span style="color:#000;">{{countryFlag}}</span>
        <!-- <img src="assets/images/{{countryFlag}}" alt=""> -->
    </button>
    <ul class="dropdown-menu">
      <li (click)="languageSwitcherAction('en-US','flag01.png')"><a class="dropdown-item" href="javascript:void(0);">
        <!-- <span><img i18n-alt src="assets/images/flag01.png" alt="USA"></span> -->
        <ng-container i18n="@@5160719833325947804">English</ng-container>
      </a>
    </li>
      <li (click)="languageSwitcherAction('fr-CA','flag03.png')">
        <a class="dropdown-item" href="javascript:void(0);">
        <!-- <span><img i18n-alt src="assets/images/flag03.png" alt="France"></span>-->
        <ng-container i18n="@@956678847762152494">French</ng-container> </a></li>
      <!--<li ><a class="dropdown-item" href="javascript:void(0);"><span ><img i18n-alt src="assets/images/flag04.png" alt="Italy"></span> <ng-container i18n="@@2427223107800831324">Italian</ng-container> </a></li>
      <li class="active"><a class="dropdown-item" href="javascript:void(0);"><span><img i18n-alt src="assets/images/flag05.png" alt="Portugal"></span> <ng-container i18n="@@1825932113518847029">Portuguese</ng-container> </a></li>
      <li><a class="dropdown-item" href="javascript:void(0);"><span><img i18n-alt src="assets/images/flag02.png" alt="Albania"></span> <ng-container i18n="@@8282620512337325099">Albanian</ng-container> </a></li>
        -->
    </ul>
</div>
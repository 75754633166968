import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { UserAuthService } from '../_services/user-auth.service';
import { ToastrService } from 'ngx-toastr';

export const AuthGuardGuard = (ActivatedRouteSnapshot: any,RouterStateSnapshot: any) => {
  const router = inject(Router);
  const auth = inject(UserAuthService);
  checkUserLogin(router, auth);
};

function checkUserLogin(route:Router, auth:UserAuthService){
  if(!auth.isUserLoggedIn()){
    route.navigate(['/login']);
   }
   return true;
}
export const checkAdminRoleGuard = (ActivatedRouteSnapshot: any,RouterStateSnapshot: any,userAuthService: UserAuthService) => {
  const router = inject(Router);
  const userService = inject(UserAuthService);
  const toasterService = inject(ToastrService);
  checkAdminRole(router,ActivatedRouteSnapshot,toasterService,userService);
};
function checkAdminRole(router:Router,activatedRouteSnapshot:ActivatedRouteSnapshot,toasterService:ToastrService,UserAuthService:UserAuthService){
  let auth = JSON.parse(localStorage.getItem('user')|| '{}');
  console.log(auth);
  if (auth.type === 'ADMIN') {
      return;
  }
  let obj = {
    userId:auth.partnerId
  }
  UserAuthService.getAdminByID().subscribe(
    (data: any) => {
      let userRole: any=data.data.roleId;
      let checkRole= userRole.find(
        (element: any) => element === activatedRouteSnapshot.data['roleId']
      )
      console.log(checkRole);
      let msg='';
      if(!checkRole && activatedRouteSnapshot.data['roleId']!==0){ 
          msg= 'You have not permission for this module';
          toasterService.error(msg,'');
          router.navigate(['/dashboard']);
      }else{
        return;
      }
      
    })
}

export class Globals {
  
    user:any;
    getUser(){
        let data = localStorage.getItem('partner_user');
        if (data != null) {
          this.user = JSON.parse(data);
        }
        return this.user;
    }
    getImageUrlBucket() {
      //return 'https://craddle.com/SaharaGo/File/';
    }
    _getFileType(filename:any = ''): string {
      let ext = '';
      let imageExtArr = ['bmp', 'cod', 'gif', 'ief', 'jpe', 'jpeg', 'jpg', 'jfif', 'svg', 'tif', 'tiff', 'ras', 'cmx', 'ico', 'pnm', 'pbm', 'pgm', 'ppm', 'rgb', 'xbm', 'xpm', 'xwd', 'png','webp'];
      let videoExtArr = ['mp2', 'mpa', 'mpe', 'mpeg', 'mpg', 'mpv2', 'mp4', 'mov', 'qt', 'lsf', 'lsx', 'asf', 'asr', 'asx', 'avi', 'movie'];
      let docExtArr = ['doc', 'docx'];
      let pdfExtArr = ['pdf'];
  
      if (filename == '') {
        return ext;
      }
      ext = filename.split('.').pop().toLowerCase();
      if (imageExtArr.includes(ext)) {
        return 'image';
      } else if (videoExtArr.includes(ext)) {
        return 'video';
      } else if (docExtArr.includes(ext)) {
        return 'document';
      } else if (pdfExtArr.includes(ext)) {
        return 'pdf';
      } else {
        return '';
      }
    }
    public static get   validImageTypes(): Array<any> { return ['image/gif', 'image/jpg', 'image/jpeg', 'image/png','image/webp','application/pdf'] }
   
    _getLangData(filename:any = ''): string {
      let langArrayData: any=[];
      langArrayData['Category_en']= "Category";
      langArrayData['Category_fr']= "Catégorie";
      langArrayData['SubCategory_en']= "SubCategory";
      langArrayData['SubCategory_fr']= "Sous-catégorie";
      langArrayData['Products_en']= "Products";
      langArrayData['Products_fr']= "Des produits";
      langArrayData['User Name_en']= "User Name";
      langArrayData['User Name_fr']= "Nom d'utilisateur";
      langArrayData['Type_en']= "Type";
      langArrayData['Type_fr']= "Type";
      langArrayData['DOB_en']= "DOB";
      langArrayData['DOB_fr']= "Date de naissance";
      langArrayData['Assigned Agent_en']= "Assigned Agent";
      langArrayData['Assigned Agent_fr']= "Agent assigné";
      langArrayData['Country_en']= "Country";
      langArrayData['Country_fr']= "Pays";
      langArrayData['Status_en']= "Status";
      langArrayData['Status_fr']= "Etat";
      langArrayData['Action_en']= "Action";
      langArrayData['Action_fr']= "Action";
     
      langArrayData['Lead ID/Offer ID_en']= "Lead Id/OfferId";
      langArrayData['Lead ID/Offer ID_fr']= "Identifiant du prospect / Identifiant de loffre";
      langArrayData['Customer_en']= "Customer";
      langArrayData['Customer_fr']= "client";
      langArrayData['Customer_en']= "Customer";
      langArrayData['Customer_fr']= "client";
      langArrayData['Agent_en']= "Agent";
      langArrayData['Agent_fr']= "Agent";
      langArrayData['Added At_en']= "Added At";
      langArrayData['Added At_fr']= "Ajouté le";
      langArrayData['Email/Phone_en']= "Email/Phone";
      langArrayData['Email/Phone_fr']= "Courriel/Téléphone";
      langArrayData['Cat/Subcat_en']= "Cat/Subcat";
      langArrayData['Cat/Subcat_fr']= "Chat/sous-chat";
      langArrayData['Lead ID_en']= "Lead Id";
      langArrayData['Lead ID_fr']= "Identifiant du prospect";
      langArrayData['Client/Relation_en']= "Client/Relation";
      langArrayData['Client/Relation_fr']= "Client/Relation";
      langArrayData['Relation_en']= "Relation";
      langArrayData['Relation_fr']= "Relation";
      langArrayData['Date_en']= "Date";
      langArrayData['Date_fr']= "Date";
      langArrayData['Request Type_en']= "Request Type";
      langArrayData['Request Type_fr']= "type de demande";
      langArrayData['Policy ID_en']="Policy ID";
      langArrayData['Policy ID_fr']="ID de politique";
      langArrayData['User Name/Type_en']="User Name/Type";
      langArrayData['User Name/Type_fr']="Nom d'utilisateur/type";
      langArrayData['Start Date/End Date_en']="Start Date/End Date";
      langArrayData['Start Date/End Date_fr']="Date de début/Date de fin";
      langArrayData['Assigned Agent_en']="Assigned Agent";
      langArrayData['Assigned Agent_fr']="Agent assigné";
      langArrayData['Enquired On_en']="Enquired On";
      langArrayData['Enquired On_fr']="Interrogé sur";
      langArrayData['Sub Category_en']="Sub Category";
      langArrayData['Sub Category_fr']="Sous-catégorie";
      langArrayData['Agent Reserve %_en']="Agent Reserve %";
      langArrayData['Agent Reserve %_fr']="% de réserve d'agent";
      langArrayData['Agent Commission %_en']= "Agent Commission %";
      langArrayData['Agent Commission %_fr']="% de commission d'agent";
      langArrayData['Added/Update On_en']="Added/Update On";
      langArrayData['Added/Update On_fr']="Ajouté/mis à jour le";
      langArrayData['Company Reserve %_en']="Company Reserve %";
      langArrayData['Company Reserve %_fr']="% de réserve de l'entreprise";
      langArrayData['Agent Name_en']="Agent Name";
      langArrayData['Agent Name_fr']="Nom d'agent";
      langArrayData['Added On_en']= "Added On";
      langArrayData['Added On_fr']= "Ajouté le";
      langArrayData['Client Name_en']="Client Name";
      langArrayData['Client Name_fr']="Nom du client";
      langArrayData['Start Date_en']="Start Date";
      langArrayData['Start Date_fr']="Date de début";
      langArrayData['End Date_en']="End Date";
      langArrayData['End Date_fr']="Date de fin";
      langArrayData['Member_en']="Member";
      langArrayData['Member_fr']="Membre";
      langArrayData['Member/Relation_en']="Member/Relation";
      langArrayData['Member/Relation_fr']="Membre/Relation";
      langArrayData['Last File Added On_en']="Last File Added On";
      langArrayData['Last File Added On_fr']="Dernier fichier ajouté le";
      langArrayData['File Name_en']="File Name";
      langArrayData['File Name_fr']="Nom de fichier";
      langArrayData['Upload On_en']="Upload On";
      langArrayData['Upload On_fr']="Télécharger sur";
      langArrayData['Added/Updated On_en']= "Added/Updated On";
      langArrayData['Added/Updated On_fr']= "Ajouté/mis à jour le";
      langArrayData['Insurance Company_en']= "Insurance Company";
      langArrayData['Insurance Company_fr']= "Compagnie d'assurance";
      langArrayData['Insurance Product_en']= "Insurance Product";
      langArrayData['Insurance Product_fr']= "Produit d'assurance";
      langArrayData['Insurance Coverage_en']= "Insurance Coverage";
      langArrayData['Insurance Coverage_fr']= "Couverture d'assurance";

      langArrayData['Task_en']= "Task";
      langArrayData['Task_fr']= "Tâche";
      langArrayData['Meeting Date_en']= "Meeting Date";
      langArrayData['Meeting Date_fr']= "Date de la réunion";
      langArrayData['Meeting Hour_en']= "Meeting Hour";
      langArrayData['Meeting Hour_fr']= "Heure de réunion";

      langArrayData['Joinees_en']= "Joinees";
      langArrayData['Joinees_fr']= "Membres";

      langArrayData['S.N_en']= "S.N";
      langArrayData['S.N_fr']= "S.N.";
      langArrayData['Name_en']= "Name";
      langArrayData['Namefr']= "Nom";
      langArrayData['Email_en']= "Email";
      langArrayData['Email_fr']= "E-mail";
      langArrayData['Role_en']= "Role";
      langArrayData['Role_fr']= "Rôle";
      langArrayData['Partner/Agent_en']= "Partner/Agent";
      langArrayData['Partner/Agent_fr']= "Partenaire/Agent";
      langArrayData['Partner_en']= "Partner";
      langArrayData['Partner_fr']= "Partenaire";
      langArrayData['Age/Dob_en']= "Age/Dob";
      langArrayData['Age/Dob_fr']= "Âge/naissance";
      langArrayData['Customers_en']= "Customers";
      langArrayData['Customers_fr']= "Clients";
      langArrayData['Address_en']= "Address";
      langArrayData['Address_fr']= "Adresse";
      langArrayData['Name_en']= "Name";
      langArrayData['Name_fr']= "Nom";
      langArrayData['Company Name_en']= "Company Name";
      langArrayData['Company Name_fr']= "Nom de l'entreprise";
      langArrayData['Website_en']= "Website";
      langArrayData['Website_fr']= "Site internet";
      langArrayData['Commission (%)_en']= "Commission (%)";
      langArrayData['Commission (%)_fr']= "Commission (%)";
      langArrayData['Company/Product_en']= "Company/Product";
      langArrayData['Company/Product_fr']= "Entreprise/Produit";
      langArrayData['Customer/Policy_en']= "Customer/Policy";
      langArrayData['Customer/Policy_fr']= "Client/Politique";
      langArrayData['Agent_en']= "Agent";
      langArrayData['Agent_fr']= "Conseiller";
      langArrayData['Start/End Date_en']= "Start/End Date";
      langArrayData['Start/End Date_fr']= "Date de début/fin";
      langArrayData['Gross Income_en']= "Gross Income";
      langArrayData['Gross Income_fr']= "Revenu brut";
      langArrayData['Partner Reserve_en']= "Partner Reserve";
      langArrayData['Partner Reserve_fr']= "Réserve partenaire";
      langArrayData['Agent Commision/Reserve_en']= "Agent Commision/Reserve";
      langArrayData['Agent Commision/Reserve_fr']= "Commission/Réserve Conseiller";
      langArrayData['Proposal Shared_en']= "Proposal Shared";
      langArrayData['Proposal Shared_fr']= "Propositions partagées";
      langArrayData['Contract Signed_en']= "Contract Signed";
      langArrayData['Contract Signed_fr']= "Contrats signés";
      langArrayData['Total Amount_en']= "Total Amount";
      langArrayData['Total Amount_fr']= "Montant total";
      langArrayData['Total Income_en']= "Total Income";
      langArrayData['Total Income_fr']= "Revenu total";
      langArrayData['Subscriber Email_en']= "Subscriber Email";
      langArrayData['Subscriber Email_fr']= "E-mail de l'abonné";
      langArrayData['Template Name_en']= "Template Name";
      langArrayData['Template Name_fr']= "Nom du modèle";
      langArrayData['Created By_en']= "Created By";
      langArrayData['Created By_fr']= "Créé par";
      langArrayData['Policy Id_en']= "Policy Id";
      langArrayData['Policy Id_fr']= "ID de stratégie";
      langArrayData['Customer Name_en']= "Customer Name";
      langArrayData['Customer Name_fr']= "Nom du client";
      langArrayData['Username/Type_en']= "Username/Type";
      langArrayData['Username/Type_fr']= "Nom d'utilisateur/Type";
      langArrayData['Total files added_en']= "Total files added";
      langArrayData['Total files added_fr']= "Total des fichiers ajoutés";
      langArrayData['Last file added on_en']= "Last file added on";
      langArrayData['Last file added on_fr']= "Dernier fichier ajouté le";
      langArrayData['Added By_en']= "Added By";
      langArrayData['Added By_fr']= "Ajouté par";
      langArrayData['Uploaded On_en']= "Uploaded On";
      langArrayData['Uploaded On_fr']= "Téléchargé le";
      langArrayData['Uploaded By_en']= "Uploaded By";
      langArrayData['Uploaded By_fr']= "Telechargé par";
      return langArrayData;
;    }

_getLangMessageData(filename:any = ''): string {
  let langArrayData: any=[];
  langArrayData['signupsuccess_en']= "Category";
  langArrayData['signupsuccess_fr']= "Catégorie";
  langArrayData['Fillallerror_en']= "Please fill the all required fields.";
  langArrayData['Fillallerror_fr']= "Veuillez remplir tous les champs obligatoires.";
  langArrayData['UserNotExisterror_en']= "User Does not Exists.";
  langArrayData['UserNotExisterror_fr']= "L'utilisateur n'existe pas.";
  langArrayData['Otperror_en']= "Please Enter OTP.";
  langArrayData['Otperror_fr']= "Veuillez saisir OTP.";
  langArrayData['RestPasswordsuccess_en']= "Password set successfully.Please login with New Password";
  langArrayData['RestPasswordsuccess_fr']= "Mot de passe défini avec succès. Veuillez vous connecter avec un nouveau mot de passe.";
  langArrayData['Updateprofile_en']= "Profile Updated Successfully";
  langArrayData['Updateprofile_fr']= "Mise à jour du profil réussie";
  langArrayData['Passwordsuccess_en']= "Passwword changed Successfully";
  langArrayData['Passwordsuccess_fr']= "Le mot de passe a été changé avec succès";
  langArrayData['AssignPartner_en']= "Partner Assigned Succuessfully";
  langArrayData['AssignPartner_fr']= "Partenaire attribué avec succès";
  langArrayData['PartnerRequired_en']= "Partner is required.";
  langArrayData['PartnerRequired_fr']= "Un partenaire est requis.";
  langArrayData['AgentRequired_en']= "Agent is required.";
  langArrayData['AgentRequired_fr']= "Conseiller est requis.";
  langArrayData['AssignAgent_en']= "Agent Assigned Succuessfully";
  langArrayData['AssignAgent_fr']= "Agent affecté avec succès";
  langArrayData['BlockedCustomer_en']= "Customer Blocked Successfully";
  langArrayData['BlockedCustomer_fr']= "Client bloqué avec succès";
  langArrayData['UnBlockedCustomer_en']= "Customer UnBlocked Successfully";
  langArrayData['UnBlockedCustomer_fr']= "Client débloqué avec succès";
  langArrayData['ApprovedPartner_en']= "Partner Approved Successfully";
  langArrayData['ApprovedPartner_fr']= "Partenaire approuvé avec succès";
  langArrayData['BlockedPartner_en']= "Partner Blocked Successfully";
  langArrayData['BlockedPartner_fr']= "Partenaire bloqué avec succès";
  langArrayData['UnBlockedPartner_en']= "Partner UnBlocked Successfully";
  langArrayData['UnBlockedPartner_fr']= "Partenaire débloqué avec succès";
  langArrayData['NotAssignd_en']= "Not Assigned";
  langArrayData['NotAssignd_fr']= "Pas assigné";
  langArrayData['UserPersonal_en']= "Personal";
  langArrayData['UserPersonal_fr']= "Privé";
  langArrayData['UserCompany_en']= "Company";
  langArrayData['UserCompany_fr']= "Company";
  langArrayData['Approved_en']= "Approved";
  langArrayData['Approved_fr']= "Approuvé";
  langArrayData['Pending_en']= "Pending";
  langArrayData['Pending_fr']= "En attente";
  langArrayData['Blocked_en']= "Blocked";
  langArrayData['Blocked_fr']= "Bloqué";
  langArrayData['Active_en']= "Active";
  langArrayData['Active_fr']= "Active";
  langArrayData['UpdatePartner_en']= "Partner Updated Successfully";
  langArrayData['UpdatePartner_fr']= "Partenaire mis à jour avec succès";
  langArrayData['contractAdd_en']= "Contract Added SUccessfully";
  langArrayData['contractAdd_fr']= "Contrat ajouté avec succès";
  langArrayData['CompanyAdd_en']= "Insurance Company added successfully!";
  langArrayData['CompanyAdd_fr']= "Compagnie d'assurance ajoutée avec succès !";
  langArrayData['CompanyUpdate_en']= "Insurance Company updated successfully!";
  langArrayData['CompanyUpdate_fr']= "Compagnie d'assurance mise à jour avec succès !";
  langArrayData['CompanyDelete_en']= "Insurance Company deleted successfully!";
  langArrayData['CompanyDelete_fr']= "Compagnie d'assurance supprimée avec succès !";
  langArrayData['ProductAdd_en']= "Insurance Product added successfully!";
  langArrayData['ProductAdd_fr']= "Produit d'assurance ajoutée avec succès !";
  langArrayData['ProductUpdate_en']= "Insurance Product updated successfully!";
  langArrayData['ProductUpdate_fr']= "Produit d'assurance mise à jour avec succès !";
  langArrayData['ProductDelete_en']= "Insurance Product deleted successfully!";
  langArrayData['ProductDelete_fr']= "Produit d'assurance supprimée avec succès !";
  langArrayData['CoverageAdd_en']= "Insurance Coverage added successfully!";
  langArrayData['CoverageAdd_fr']= "Couverture d'assurance ajoutée avec succès !";
  langArrayData['CoverageUpdate_en']= "Insurance Coverage updated successfully!";
  langArrayData['CoverageUpdate_fr']= "Couverture d'assurance mise à jour avec succès !";
  langArrayData['CoverageDelete_en']= "Insurance Coverage deleted successfully!";
  langArrayData['CoverageDelete_fr']= "Couverture d'assurance supprimée avec succès !";
  langArrayData['CustomerAdd_en']= "Customer added successfully!";
  langArrayData['CustomerAdd_fr']= "Client ajouté avec succès !";
  langArrayData['CustomerUpdate_en']= "Customer Updated successfully!";
  langArrayData['CustomerUpdate_fr']= "Client mis à jour avec succès !";
  langArrayData['AgentAdd_en']= "Agent added successfully!";
  langArrayData['AgentAdd_fr']= "Conseiller ajouté avec succès !";
  langArrayData['AgentUpdate_en']= "Agent Updated successfully!";
  langArrayData['AgentUpdate_fr']= "Conseiller mis à jour avec succès !";
  langArrayData['ACCEPTED_en']= "Accepted";
  langArrayData['ACCEPTED_fr']= "Acceptée";
  langArrayData['SAVED_en']= "Saved";
  langArrayData['SAVED_fr']= "ENREGISTRÉE";
  langArrayData['PENDING_en']= "Pending";
  langArrayData['PENDING_fr']= "EN ATTENTE";
  langArrayData['POLICY_CREATED_en']= "Policy Created";
  langArrayData['POLICY_CREATED_fr']= "Police active";
  langArrayData['REJECTED_en']= "Rejected";
  langArrayData['REJECTED_fr']= "Refusée";
  langArrayData['SELF_en']= "Self";
  langArrayData['SELF_fr']= "Soi-même";
  langArrayData['Date_en']= "Date";
  langArrayData['Date_fr']= "Date";
  langArrayData['Request Type_en']= "Request Type";
  langArrayData['Request Type_fr']= "type de demande";
  langArrayData['Policy ID_en']="Policy ID";
  langArrayData['Policy ID_fr']="ID de politique";
  langArrayData['User Name/Type_en']="User Name/Type";
  langArrayData['User Name/Type_fr']="Nom d'utilisateur/type";
  langArrayData['Start Date/End Date_en']="Start Date/End Date";
  langArrayData['Start Date/End Date_fr']="Date de début/Date de fin";
  langArrayData['Assigned Agent_en']="Assigned Agent";
  langArrayData['Assigned Agent_fr']="Agent assigné";
  langArrayData['Enquired On_en']="Enquired On";
  langArrayData['Enquired On_fr']="Interrogé sur";
  langArrayData['Sub Category_en']="Sub Category";
  langArrayData['Sub Category_fr']="Sous-catégorie";
  langArrayData['Agent Reserve %_en']="Agent Reserve %";
  langArrayData['Agent Reserve %_fr']="% de réserve d'agent";
  langArrayData['Agent Commission %_en']= "Agent Commission %";
  langArrayData['Agent Commission %_fr']="% de commission d'agent";
  langArrayData['Added/Update On_en']="Added/Update On";
  langArrayData['Added/Update On_fr']="Ajouté/mis à jour le";
  langArrayData['Company Reserve %_en']="Company Reserve %";
  langArrayData['Company Reserve %_fr']="% de réserve de l'entreprise";
  langArrayData['Agent Name_en']="Agent Name";
  langArrayData['Agent Name_fr']="Nom d'agent";
  langArrayData['Added On_en']= "Added On";
  langArrayData['Added On_fr']= "Ajouté le";
  langArrayData['Client Name_en']="Client Name";
  langArrayData['Client Name_fr']="Nom du client";
  langArrayData['Start Date_en']="Start Date";
  langArrayData['Start Date_fr']="Date de début";
  langArrayData['End Date_en']="End Date";
  langArrayData['End Date_fr']="Date de fin";
  langArrayData['Member_en']="Member";
  langArrayData['Member_fr']="Membre";
  langArrayData['Member/Relation_en']="Member/Relation";
  langArrayData['Member/Relation_fr']="Membre/Relation";
  langArrayData['Last File Added On_en']="Last File Added On";
  langArrayData['Last File Added On_fr']="Dernier fichier ajouté le";
  langArrayData['File Name_en']="File Name";
  langArrayData['File Name_fr']="Nom de fichier";
  langArrayData['Upload On_en']="Upload On";
  langArrayData['Upload On_fr']="Télécharger sur";
  langArrayData['Added/Updated On_en']= "Added/Updated On";
  langArrayData['Added/Updated On_fr']= "Ajouté/mis à jour le";
  langArrayData['Insurance Company_en']= "Insurance Company";
  langArrayData['Insurance Company_fr']= "Compagnie d'assurance";
  langArrayData['Insurance Product_en']= "Insurance Product";
  langArrayData['Insurance Product_fr']= "Produit d'assurance";
  langArrayData['Insurance Coverage_en']= "Insurance Coverage";
  langArrayData['Insurance Coverage_fr']= "Couverture d'assurance";

  langArrayData['Task_en']= "Task";
  langArrayData['Task_fr']= "Tâche";
  langArrayData['Meeting Date_en']= "Meeting Date";
  langArrayData['Meeting Date_fr']= "Date de la réunion";
  langArrayData['Meeting Hour_en']= "Meeting Hour";
  langArrayData['Meeting Hour_fr']= "Heure de réunion";

  langArrayData['Joinees_en']= "Joinees";
  langArrayData['Joinees_fr']= "Membres";

  langArrayData['S.N_en']= "S.N";
  langArrayData['S.N_fr']= "S.N.";
  langArrayData['Name_en']= "Name";
  langArrayData['Namefr']= "Nom";
  langArrayData['Email_en']= "Email";
  langArrayData['Email_fr']= "E-mail";
  langArrayData['Role_en']= "Role";
  langArrayData['Role_fr']= "Rôle";
  langArrayData['Partner/Agent_en']= "Partner/Agent";
  langArrayData['Partner/Agent_fr']= "Partenaire/Agent";
  langArrayData['Partner_en']= "Partner";
  langArrayData['Partner_fr']= "Partenaire";
  langArrayData['Age/Dob_en']= "Age/Dob";
  langArrayData['Age/Dob_fr']= "Âge/naissance";
  langArrayData['Customers_en']= "Customers";
  langArrayData['Customers_fr']= "Clients";
  langArrayData['Address_en']= "Address";
  langArrayData['Address_fr']= "Adresse";
  langArrayData['Name_en']= "Name";
  langArrayData['Name_fr']= "Nom";
  langArrayData['Company Name_en']= "Company Name";
  langArrayData['Company Name_fr']= "Nom de l'entreprise";
  langArrayData['Website_en']= "Website";
  langArrayData['Website_fr']= "Site internet";
  langArrayData['Commission (%)_en']= "Commission (%)";
  langArrayData['Commission (%)_fr']= "Commission (%)";
  langArrayData['Company/Product_en']= "Company/Product";
  langArrayData['Company/Product_fr']= "Entreprise/Produit";
  langArrayData['Customer/Policy_en']= "Customer/Policy";
  langArrayData['Customer/Policy_fr']= "Client/Politique";
  langArrayData['Agent_en']= "Agent";
  langArrayData['Agent_fr']= "Conseiller";
  langArrayData['Start/End Date_en']= "Start/End Date";
  langArrayData['Start/End Date_fr']= "Date de début/fin";
  langArrayData['Gross Income_en']= "Gross Income";
  langArrayData['Gross Income_fr']= "Revenu brut";
  langArrayData['Partner Reserve_en']= "Partner Reserve";
  langArrayData['Partner Reserve_fr']= "Réserve partenaire";
  langArrayData['Agent Commision/Reserve_en']= "Agent Commision/Reserve";
  langArrayData['Agent Commision/Reserve_fr']= "Commission/Réserve Conseiller";
  langArrayData['Proposal Shared_en']= "Proposal Shared";
  langArrayData['Proposal Shared_fr']= "Propositions partagées";
  langArrayData['Contract Signed_en']= "Contract Signed";
  langArrayData['Contract Signed_fr']= "Contrats signés";
  langArrayData['Total Amount_en']= "Total Amount";
  langArrayData['Total Amount_fr']= "Montant total";
  langArrayData['Total Income_en']= "Total Income";
  langArrayData['Total Income_fr']= "Revenu total";
  langArrayData['Subscriber Email_en']= "Subscriber Email";
  langArrayData['Subscriber Email_fr']= "E-mail de l'abonné";
  langArrayData['Template Name_en']= "Template Name";
  langArrayData['Template Name_fr']= "Nom du modèle";
  langArrayData['Created By_en']= "Created By";
  langArrayData['Created By_fr']= "Créé par";
  langArrayData['Policy Id_en']= "Policy Id";
  langArrayData['Policy Id_fr']= "ID de stratégie";
  langArrayData['Customer Name_en']= "Customer Name";
  langArrayData['Customer Name_fr']= "Nom du client";
  langArrayData['Username/Type_en']= "Username/Type";
  langArrayData['Username/Type_fr']= "Nom d'utilisateur/Type";
  langArrayData['Total files added_en']= "Total files added";
  langArrayData['Total files added_fr']= "Total des fichiers ajoutés";
  langArrayData['Last file added on_en']= "Last file added on";
  langArrayData['Last file added on_fr']= "Dernier fichier ajouté le";
  langArrayData['Added By_en']= "Added By";
  langArrayData['Added By_fr']= "Ajouté par";
  langArrayData['Uploaded On_en']= "Uploaded On";
  langArrayData['Uploaded On_fr']= "Téléchargé le";
  langArrayData['Uploaded By_fr']= "Uploaded By";
  langArrayData['Uploaded By_fr']= "Telechargé par";
  langArrayData['BlockedCustomercsa_en']= "Customer Executive Blocked Successfully";
  langArrayData['BlockedCustomercsa_fr']= "Responsable client bloqué avec succès";
  langArrayData['UnBlockedCustomercsa_en']= "Customer Executive UnBlocked Successfully";
  langArrayData['UnBlockedCustomercsa_fr']= "Responsable client débloqué avec succès";
  return langArrayData;
 }


agGridLocalText(lng: any=''){ 
  if(lng==='en'){
    return {
      of: 'of'
    }
  }else{
    return {
      of: 'sur'
    }
  }
}

insuranceTypeLng(lng: any=''){ 
  if(lng==='us'){
    return [{label:"Health", value:'HEALTH'},{label:"Travel", value:'TRAVEL'},{label:"Building", value:'BUILDING'},{label:"Motor Vehicles", value:'MOTOR_VEHICLE'},{label:"Rc Household", value:'RC_HOUSEHOLD'},{label:"Legal Protection", value:'LEGAL_PROTECTION'},{label:"Construction", value:'CONSTRUCTION'},{label:"Quotation Request Valuables", value:'QUOTATION_REQUEST'},{label:"Housing Rental Guaratee Offer", value:'HOUSING_RENTAL'},{label:"Animal", value:'ANIMAL'},{label:"3rd Piller", value:'THIRD_PILLAR'},{label:"Business Legal Protection", value:'BUSINESS_LEGAL_PROTECTION'},{label:"Business Insurance offer", value:'BUSINESS_INSURANCE_OFFER'},{label:"Companies Things - RCE", value:'BUSINESS_COMPANY_THINGS'}];
  }else if(lng==='fr'){
    return [{label:"Caisse maladie", value:'HEALTH'},{label:"Voyage", value:'TRAVEL'},{label:"Bâtiment", value:'BUILDING'},{label:"Véhicules à moteur", value:'MOTOR_VEHICLE'},{label:"RC ménage", value:'RC_HOUSEHOLD'},{label:"Protection juridique", value:'LEGAL_PROTECTION'},{label:"Construction", value:'CONSTRUCTION'},{label:"Objets de valeur", value:'QUOTATION_REQUEST'},{label:"Grantie de loyer", value:'HOUSING_RENTAL'},{label:"Animaux", value:'ANIMAL'},{label:"3e pilier", value:'THIRD_PILLAR'},{label:"Protection juridique entreprise", value:'BUSINESS_LEGAL_PROTECTION'},{label:"Offre d'assurance entreprise", value:'BUSINESS_INSURANCE_OFFER'},{label:"Choses des entreprises - RCE", value:'BUSINESS_COMPANY_THINGS'}];
  }else{
    return [{label:"Health", value:'HEALTH'},{label:"Travel", value:'TRAVEL'},{label:"Building", value:'BUILDING'},{label:"Motor Vehicles", value:'MOTOR_VEHICLE'},{label:"Rc Household", value:'RC_HOUSEHOLD'},{label:"Legal Protection", value:'LEGAL_PROTECTION'},{label:"Construction", value:'CONSTRUCTION'},{label:"Quotation Request Valuables", value:'QUOTATION_REQUEST'},{label:"Housing Rental Guaratee Offer", value:'HOUSING_RENTAL'},{label:"Animal", value:'ANIMAL'},{label:"3rd Piller", value:'THIRD_PILLAR'},{label:"Business Legal Protection", value:'BUSINESS_LEGAL_PROTECTION'},{label:"Business Insurance offer", value:'BUSINESS_INSURANCE_OFFER'},{label:"Companies Things - RCE", value:'BUSINESS_COMPANY_THINGS'}];
  }
}
  }

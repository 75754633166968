import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent as HomeHeaderComponent} from './Home/Common/header/header.component';
import { FooterComponent as HomeFooterComponent } from './Home/Common/footer/footer.component';
import { DashboardLayoutComponent } from './Layouts/dashboard-layout/dashboard-layout.component';
import { HomeLayoutComponent } from './Layouts/home-layout/home-layout.component';
import { HeaderComponent as DashboardHeaderComponent} from './Dashboard/Common/header/header.component';
import { SidebarComponent } from './Dashboard/Common/sidebar/sidebar.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SharedModule } from './shared/shared.module';
//import { ProfileComponent } from './Dashboard/profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './globals';
@NgModule({
  declarations: [
    AppComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    DashboardLayoutComponent,
    HomeLayoutComponent,
    DashboardHeaderComponent,
    SidebarComponent,
    PagenotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgScrollbarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ToastrModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  DATE_FORMAT: 'dd.MM.yyyy',
  DATE_TIME_FORMAT: 'MM/dd/yyyy HH:mm:ss',
  VDATE_TIME_FORMAT: 'dd.MM.yyyy HH:mm:ss',
  AG_DATE_FORMAT: 'DD.MM.yyyy',
  AG_DATE_TIME_FORMAT: 'MM/DD/yyyy HH:mm:ss',
  USERAPIENDPOINT:"https://identity.dev.ltytech.ch/api/ims/v1/",
  COMMUNICATORAPIURL:"https://communicator.dev.ltytech.ch/api/communicator/v1/",
  DOCUMENTAPIURL:"https://document-store.dev.ltytech.ch/api/document-store/v1/",
  INSURANCEAPIURL:"https://insurance-aggregator.dev.ltytech.ch/api/insurance/v1/",
  CATALOGUEAPIURL:"https://catalogue.dev.ltytech.ch/api/catalogue/v1/",
  FEEAPIENDPOINT:"https://fee.dev.ltytech.ch/api/fee/v1/",
  ROLEAPIENDPOINT:"https://role-access.dev.ltytech.ch/api/role-access/v1/",
  //FILEPATHPOINT:"http://44.203.193.10/backend/uploads/",

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

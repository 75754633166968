import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  _baseURL:any;
  deviceInfo:any;
  _headers:any;
  _headersOTP:any;
  _user:any;
  _headersWithToken:any;
  _CommunicatorbaseURL:any;
  _CataloguebaseURL:any;
  _headersCatalogueToken:any;
  _FeebaseURL: string;
  _DocumentbaseURL: any;
  _headersWithFile: any;
  _InsurancebaseURL: any;
  constructor(private httpClient: HttpClient, private deviceService:DeviceDetectorService) {

    this._baseURL = environment.USERAPIENDPOINT;
    this._CommunicatorbaseURL = environment.COMMUNICATORAPIURL;
    this._CataloguebaseURL = environment.CATALOGUEAPIURL;
    this._FeebaseURL = environment.FEEAPIENDPOINT;
    this._DocumentbaseURL = environment.DOCUMENTAPIURL;
    this._InsurancebaseURL = environment.INSURANCEAPIURL;
    this.deviceInfo = this.deviceService.getDeviceInfo();
  //console.log(this.deviceInfo);
  let devicedetail:any= {
    platform: 'Website',
    browser: this.deviceInfo.browser,
    browserVersion: this.deviceInfo.browser_version,
    osVersion: this.deviceInfo.os_version,
    deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
    appVersion: '',
    ipAddress: '',
    macAddress: '',
  }

  this._headers = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', JSON.stringify(devicedetail));
  this._headersOTP = new HttpHeaders().set('content-type', 'application/json').set('data-hash', 'test').set('device-id', 'test').set('User_Agent', JSON.stringify(devicedetail));
  if (this.isUserLoggedIn()) {
    this.getCurrentUser()
 
    this._headersWithToken = new HttpHeaders().set('content-type', 'application/json').set('Access-Token',this._user.token).set('User_Agent', JSON.stringify(devicedetail));
    this._headersCatalogueToken = new HttpHeaders().set('content-type', 'application/json').set('Access-Token',this._user.token).set('User_Agent', JSON.stringify(devicedetail));
    this._headersWithFile = new HttpHeaders().set('User_Agent', JSON.stringify(devicedetail)).set('Access-Token',this._user.token);
 }
   }

   isUserLoggedIn(): boolean {
    let data = localStorage.getItem('user');
   // console.log(data);
    if (data != null) {
      return true;
    }
    return false;
  }
  
  getCurrentUser(): any {
    let data:any = localStorage.getItem('user');
    if (data != null) {
      this._user = JSON.parse(data);
    }
    //console.log(this._user);
    return this._user;
  }

  login(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/signin', obj, { headers: this._headers });
  }

  customers(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/get/users', obj, { headers: this._headersWithToken });
  }


  customersByPartner(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/get/partner/users', obj, { headers: this._headersWithToken });
  }

  familymembers(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/get/user/family-member', obj, { headers: this._headersWithToken });
  }

  familymembersDetail(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/get/family-member', obj, { headers: this._headersWithToken });
  }

  partners(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'partner/get/active/partners', {
   searchBy: obj?.searchBy?obj.searchBy:''
    }, { headers: this._headersWithToken });
  }

  Allpartners(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/get/partners', obj, { headers: this._headersWithToken });
  }
  

  Allagents(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/get/agents', obj, { headers: this._headersWithToken });
  }

  assignpartner(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/assign/partner', obj, { headers: this._headersWithToken });
  }

 

  assignAgent(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'partner/assign/agent', obj, { headers: this._headersWithToken });
  }
  
  UpdatepartnerState(obj: any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'admin/update/partner/state', obj, { headers: this._headersWithToken });
  }

  UpdateuserState(obj: any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'admin/update/user/state', obj, { headers: this._headersWithToken });
  }

  approvepartner(obj: any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'partner/block', obj, { headers: this._headersWithToken });
  }

  getAdminByID(): Observable<any> {
  
    return this.httpClient.get(this._baseURL + 'admin/get/profiles', { headers: this._headersWithToken });
  }

  getAdminProfile(): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/get/profiles', {adminId: this._user.adminId}, { headers: this._headersWithToken });
  }

  getPartnerDetail(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'partner/details', obj, { headers: this._headersWithToken });
  }

  getCustomerDetail(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'user/details', obj, { headers: this._headersWithToken });
  }

  UpdateCustomer(obj:any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'user/update', obj, { headers: this._headersWithToken });
  }

  UpdateProfile(obj:any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'admin/update', obj, { headers: this._headersWithToken });
  }

  UpdatePassword(obj:any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'admin/change/password', {adminId:this._user.adminId, oldPassword:obj.oldPassword, newPassword:obj.newPassword}, { headers: this._headersWithToken });
  }

  forgetpassword(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/forgot/password', obj, { headers: this._headers });
  }
  setpassword(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/verify/forgot/password', obj, { headers: this._headers });
  }
  resendotp(obj: any): Observable<any> {
  
    return this.httpClient.put(this._CommunicatorbaseURL + 'otp/resend', obj, { headers: this._headersOTP });
  }

  GetNotificationList(obj: any): Observable<any> {
  
    return this.httpClient.post(this._CommunicatorbaseURL + 'communication/get/notification', obj, { headers: this._headersWithToken });
  }
  _getCountyList(): Observable<any> {
  
    return this.httpClient.get(this._baseURL + 'config/get/countries', { headers: this._headersWithToken });
  }
  _getStateList(obj:any): Observable<any> {
  
    return this.httpClient.get(this._baseURL + 'config/get/states/'+obj, { headers: this._headersWithToken });
  }

  _getCityList(obj:any): Observable<any> {
  
    return this.httpClient.get(this._baseURL + 'config/get/cities/'+obj, { headers: this._headersWithToken });
  }

  _addCategory(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'category/add', obj, { headers: this._headersCatalogueToken });
  }

  _updateCategory(obj:any): Observable<any> {
  
    return this.httpClient.put(this._CataloguebaseURL + 'category/update', obj, { headers: this._headersCatalogueToken });
  }
  _deleteCategory(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'category/delete', obj, { headers: this._headersCatalogueToken });
  }

  _listAllCategory(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'category/get/all', obj, { headers: this._headersCatalogueToken });
  }
  
  _addProduct(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'product/add', obj, { headers: this._headersCatalogueToken });
  }

  _getAllSubcategory(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'category/get/subcategories', obj, { headers: this._headersCatalogueToken });
  }

  _getCategoryById(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'category/get', obj, { headers: this._headersCatalogueToken });
  }
  _updateProduct(obj:any): Observable<any> {
  
    return this.httpClient.put(this._CataloguebaseURL + 'product/update', obj, { headers: this._headersCatalogueToken });
  }

  _listAllProduct(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'product/get/category', obj, { headers: this._headersCatalogueToken });
  }

  _deleteProduct(obj:any): Observable<any> {
  
    return this.httpClient.post(this._CataloguebaseURL + 'product/delete', obj, { headers: this._headersCatalogueToken });
  }

  addPartner(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'partner/signup', obj, { headers: this._headersWithToken });
  }

  updatePartner(obj:any): Observable<any>{
    return this.httpClient.put(this._baseURL + 'partner/update', obj, { headers: this._headersWithToken });
  }

  _getCommissionDetailsByAgent(obj: any): Observable<any>{
    return this.httpClient.post(this._FeebaseURL + 'get/agent/agent-commission', obj,{headers: this._headersWithToken });
  }

  _deleteCommision(obj:any): Observable<any>{
    return this.httpClient.post(this._FeebaseURL + 'delete/agent-commission',obj,{headers: this._headersWithToken });
  }

  _getFeeCategory(obj:any): Observable<any>{
    
    return this.httpClient.post(this._FeebaseURL + 'get/category',obj,{headers: this._headersWithToken });
  }

  _getFeeSubCategory(obj:any): Observable<any>{
    
    return this.httpClient.post(this._FeebaseURL + 'get/sub-category',obj,{headers: this._headersWithToken });
  }

  _updateCommission(obj:any): Observable<any>{
    return this.httpClient.put(this._FeebaseURL + 'update/agent-commission', obj,{headers: this._headersWithToken });
  }

  _addCommission(obj:any): Observable<any>{
    return this.httpClient.post(this._FeebaseURL + 'add/agent-commission', obj,{headers: this._headersWithToken });
  }

  UploadDocument(obj:any): Observable<any> {
    console.log(obj);
    var form = new FormData();
    form.append("docName", obj.docName);
    form.append("userId", obj.userId);
    if(obj.memberId !== undefined){ 
    form.append("memberId", obj.memberId);
    }
    if(obj.uploadedBy !== undefined){ 
    form.append("uploadedBy", obj.uploadedBy);
    }
    if(obj.doc !== undefined){ 
      form.append("doc",obj.doc, obj.doc.name);
    }

    return this.httpClient.post(this._DocumentbaseURL+'upload', form, { headers: this._headersWithFile });
  }

  getUserLeadList(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/user/leads', obj, { headers: this._headersWithToken });
  }
  
  Allleads(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/leads', obj, { headers: this._headersWithToken });
  }

  getadminDashboardInfo(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/admin/dashboard-info', obj, { headers: this._headersWithToken });
  }

  AllProposals(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/proposals', obj, { headers: this._headersWithToken });
  }

  _AllIsuranceType(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/static/data', obj, { headers: this._headersWithToken });
  }

  _AddExistingContract(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'add/existing/contract', obj, { headers: this._headersWithToken });
  }

  getExpiringContracts(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/expired/proposals', obj, { headers: this._headersWithToken });
  }

  getAgendaList(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'agenda/get/agenda', obj,{headers: this._headersWithToken });
  }

  GetProposalDetail(obj:any): Observable<any> {
    // obj['userId'] = this._user.userId;
     return this.httpClient.post(this._InsurancebaseURL + 'get/proposal', obj, { headers: this._headersWithToken });
   }
   GetLeadDetail(obj:any): Observable<any> {
    // obj['userId'] = this._user.userId;
     return this.httpClient.post(this._InsurancebaseURL + 'get/lead/details', obj, { headers: this._headersWithToken });
   }
   AllSubmissions(obj:any): Observable<any> {
    return this.httpClient.post(this._InsurancebaseURL + 'get/submissions', obj, { headers: this._headersWithToken });
  }
  
  AllDocuments(obj:any): Observable<any> {
    return this.httpClient.post(this._DocumentbaseURL + 'get/user/docs', obj, { headers: this._headersWithToken });
  }
  AllMemberDocuments(obj:any): Observable<any> {
    return this.httpClient.post(this._DocumentbaseURL + 'get/member/docs', obj, { headers: this._headersWithToken });
  }

  registration(obj: any): Observable<any> {
    return this.httpClient.post(this._baseURL + 'user/signup', obj, { headers: this._headersWithToken });
  }
  
  _getActiveCustomerList(obj: any): Observable<any>{

    return this.httpClient.post(this._baseURL + 'get/partner/active/users',obj, {headers: this._headersWithToken });
  }

  _getActiveAllCustomerList(obj:any): Observable<any>{

    return this.httpClient.post(this._baseURL + 'user/get/all/active', obj, {headers: this._headersWithToken });
  }

  _getAgendaList(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'agenda/get/agenda', obj,{headers: this._headersWithToken });
  }
  _addAgenda(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'agenda/add/agenda', obj,{headers: this._headersWithToken });
  }

  _updateAgenda(obj: any): Observable<any>{
    return this.httpClient.put(this._baseURL + 'agenda/update/agenda', obj,{headers: this._headersWithToken });
  }

  _deleteAgenda(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'agenda/delete/agenda', obj, { headers: this._headersWithToken });
  }

  _synAgendaGoogleCalendar(obj: any, gtoken:any): Observable<any>{
    
    // obj={
    //   'summary': 'Google I/O 2015',
    //   'location': '800 Howard St., San Francisco, CA 94103',
    //   'description': 'A chance to hear more about Google\'s developer products.',
    //   'start': {
    //     'dateTime': '2023-08-28T09:00:00-07:00',
    //     'timeZone': 'America/Los_Angeles',
    //   },
    //   'end': {
    //     'dateTime': '2023-08-30T17:00:00-07:00',
    //     'timeZone': 'America/Los_Angeles',
    //   }
    // }

    console.log(gtoken);
    return this.httpClient.post('https://www.googleapis.com/calendar/v3/calendars/primary/events', obj, { headers: { Authorization: `Bearer ${gtoken}` } });
  }
  _getModuleList(): Observable<any>{
    return this.httpClient.get(this._baseURL + 'admin/get/modules',{headers: this._headersWithToken });
  }
  _getCsaList(): Observable<any> {
    return this.httpClient.get(this._baseURL + 'admin/get/csa', { headers: this._headersWithToken });
  }
  addCsa(obj: any): Observable<any> {
    return this.httpClient.post(this._baseURL + 'admin/add/csa', obj, { headers: this._headersWithToken });
  }
  updateCsa(obj: any): Observable<any> {
    return this.httpClient.put(this._baseURL + 'admin/update/csa', obj, { headers: this._headersWithToken });
  }
  getCsaDetail(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'admin/get/csa/details', obj, { headers: this._headersWithToken });
  }
  UpdateCsaState(obj: any): Observable<any> {
  
    return this.httpClient.put(this._baseURL + 'admin/update/csa/state', obj, { headers: this._headersWithToken });
  }
  _logout(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'partner/signout', obj, { headers: this._headers });
  }
  _getPresignedFileUrl(filename:any) {
    let obj={
      key:filename
    }
    return new Promise((resolve, reject) => { 
      this.httpClient.post(this._DocumentbaseURL + 'get/presigned', obj, { headers:this._headersWithToken }).subscribe({
       next: (x: any) => {
       
        return resolve(x.data.preSignedUrl);
       },
       error: (err: Error) => {
        return reject(err);
       },
       complete: () => {
       },
     });
    });
  } 
}

<footer>
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-4">
                <div class="footer-logo">
                    <img src="assets/images/footer-logo.png" alt="LTY" i18n>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyright">
                    &copy; LTY 2023
                </div>
            </div>
        </div>
    </div>
</footer>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/_services/user-auth.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  VDATE_TIME_FORMAT=environment.VDATE_TIME_FORMAT;
  Admindetail: any;
  notificationList: any=[];
  totalNotification: any=0;
  logoutSubscribeData: any;
  user: any;
  constructor(private userAuthService:UserAuthService, private router:Router,private toastrService: ToastrService){
    let data:any = localStorage.getItem('user');
   if (data != null) {
     this.user = JSON.parse(data);
   }

  }
  ngOnInit(): void {
    if(this.userAuthService.isUserLoggedIn()){
      this.Admindetail=this.userAuthService.getCurrentUser();
      this.GetNotificationList();

      //setInterval(() => {
      //   this.GetNotificationList(); 
      // }, 5000);
      }
    this.GetProfileDetail();
   
  }
  _getProfile:any;
  _getLoggedInuser:any={
    firstName:'',
    lastName:''
  };
  GetProfileDetail(): any {
   
    this._getProfile = this.userAuthService.getAdminByID().subscribe({
      next: async (x: any) => {
       this._getLoggedInuser=x.data;
       this._getLoggedInuser.profilePic = await this.userAuthService._getPresignedFileUrl(x.data.profilePic);
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        console.error(err)
        localStorage.removeItem('user')
        this.router.navigate(['/login']);
      },
      complete: () => {
        
      },
    });
   
  }

  GetNotificationList(): any {
    //console.log(this._getLoggedInuser);
    let formdata={
      userId:this.Admindetail.adminId
    }
   
    this._getProfile = this.userAuthService.GetNotificationList(formdata).subscribe({
      next: (x: any) => {
       this.notificationList=x.data.notificationList;
       this.totalNotification=x.data.notificationList.length;
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        console.error(err)
       // localStorage.removeItem('user')
       // this.router.navigate(['/login']);
      },
      complete: () => {
        
      },
    });
   
  }

  SignOut(): void {
    let obj={
      token: this.user.token,
      hardSignOut: true
    }
    this.logoutSubscribeData=this.userAuthService._logout(obj)
    .subscribe({
      next: (result: any) => {
        console.log(result);
        if(result.status=="SUCCESS"){
          localStorage.clear();
          setTimeout(() => {
            this.router.navigate(['/login']);
        }, 2000);
        }
      },
      error: (err: any) => {
          let errRes: any;
          errRes = err;
          this.toastrService.error(errRes.error.error.clientErrorMessage);
      },
      complete: () => {
        console.log('complete');
      }
      });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule ,APP_BASE_HREF, PlatformLocation} from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { SelectAllCheckboxComponent } from './components/select-all-checkbox/select-all-checkbox.component';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';

//import { CustomerNavComponent } from './components/customer-nav/customer-nav.component';
//import { DataAnalysisNavComponent } from './components/data-analysis-nav/data-analysis-nav.component'; 
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    //SelectAllCheckboxComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
   // CustomerNavComponent,
    //DataAnalysisNavComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule
  ],
  exports: [FileDragDropDirective,SafeHtmlPipe, LanguageSwitcherComponent],
  providers: [
    {
        provide: APP_BASE_HREF,
        useFactory: getBaseHref,
        deps: [PlatformLocation]
    }
]
})
export class SharedModule { }
